import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/images/Logo.svg';

function SidebarSection() {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleSetting = () => {
        navigate(`/setting/server/`);
    };
    const handleLogout = () => {
        navigate('/logout/');
    };
    return (
        <div className="sidebar-menu">
            <div className="sidebar-menu-top">
                <div className="sidebar-menu-logo">
                    {' '}
                    <img src={Logo} alt="Logo" />
                </div>
            </div>

            <div className="sidebar-menu-list">
                <ul className="sidebar-menu-list-ul">
                    <li
                        className={`${
                            pathname === '/email/report/details/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/email/report/details/">
                            <div
                                className={`${
                                    pathname === '/email/report/details/'
                                        ? 'recent-mail-icon-active'
                                        : 'recent-mail-icon'
                                }`}
                            />
                            <span>Recent Mail</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/email/report/details/defer/'
                                ? 'sidebar-menu-active'
                                : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/email/report/details/defer/">
                            <div
                                className={`${
                                    pathname === '/email/report/details/defer/'
                                        ? 'recent-mail-defer-icon-active'
                                        : 'recent-mail-defer-icon'
                                }`}
                            />
                            <span>Recent Mail Defer</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/email/report/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/email/report/">
                            <div
                                className={`${
                                    pathname === '/email/report/'
                                        ? 'dashboard-icon-active '
                                        : 'dashboard-icon'
                                }`}
                            />
                            <span>Summary Report</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/email/report/daily/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/email/report/daily/">
                            <div
                                className={`${
                                    pathname === '/email/report/daily/'
                                        ? 'daily-mail-icon-active'
                                        : 'daily-mail-icon'
                                }`}
                            />
                            <span>Daily Report</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/email/report/user/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/email/report/user/">
                            <div
                                className={`${
                                    pathname === '/email/report/user/'
                                        ? 'user-mail-icon-active'
                                        : 'user-mail-icon'
                                }`}
                            />
                            <span>User Report</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/email/report/mail-account/'
                                ? 'sidebar-menu-active'
                                : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/email/report/mail-account/">
                            <div
                                className={`${
                                    pathname === '/email/report/mail-account/'
                                        ? 'acc-mail-icon-active'
                                        : 'acc-mail-icon'
                                }`}
                            />
                            <span>Mail Acc Report</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/email/report/server' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/email/report/server/">
                            <div
                                className={`${
                                    pathname === '/email/report/server/'
                                        ? 'server-mail-icon-active'
                                        : 'server-mail-icon'
                                }`}
                            />
                            <span>Server Report</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="sidebar-footer">
                <button className="sideFooter-user" type="button" id="sideFooterBtn">
                    <div
                        className="sideFooter-user-circle cursor-pointer"
                        style={{ background: '#dfjddd' }}
                    >
                        S
                    </div>
                    <p>Sourav Mallick</p>
                </button>
                <button type="button" className="sideFooterSetting" onClick={handleSetting} />
                <button type="button" className="sideFooterLogout" onClick={handleLogout} />

                <div className="sidebar-footer-menu display-none">
                    <ul>
                        <li>
                            <Link to="/manage/"> Change Profile</Link>
                        </li>
                        <li>
                            <Link to="/manage/"> Change Password</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default SidebarSection;
