import React from 'react';
import { CSVLink } from 'react-csv';
import { useLocation } from 'react-router-dom';
import IconDownload from '../../../assets/images/IconDownload.svg';
import IconSysc from '../../../assets/images/IconSync.svg';
import ConfigApi from '../../../configs/ConfigApi';
import AxiosAuth from '../../utils/AxiosAuth';

const titleData = {
    '/email/report/': 'Email Summary Report',
    '/email/report/details/': 'Email Report Detail',
    '/email/report/details/defer/': 'Email Report Detail (Defer)',
    '/email/report/daily/': 'Email Report Date Wise',
    '/email/report/user/': 'Email Report User Wise',
    '/email/report/server/': 'Email Report Server Wise',
    '/email/report/mail-account/': 'Email Report Mail Account',
    '/setting/server/': 'Setting Server',
};
function HeaderSection({ csvReport = null }) {
    const { pathname } = useLocation();

    const handleEmailScrap = () => {
        AxiosAuth.get(ConfigApi.API_EMAIL_SCRAP)
            .then((response) => {
                if (response.data.error === 0) {
                    window.location.reload();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <div className="main-stricture-header">
            <button type="button" id="sideMenu" />
            <p className="main-stricture-header-p">
                {titleData[pathname]}{' '}
                {csvReport?.data?.length > 0 ? (
                    <button type="button" className="email-scrap-btn">
                        <CSVLink {...csvReport}>
                            <img src={IconDownload} alt="" /> Export
                        </CSVLink>
                    </button>
                ) : null}
                <button type="button" className="email-scrap-btn" onClick={handleEmailScrap}>
                    <img src={IconSysc} alt="" /> Email Import
                </button>
            </p>
        </div>
    );
}

export default HeaderSection;
