const api = process.env.REACT_APP_API_URL;

const ConfigApi = {
    API_APP_INIT: `${api}/api/init`,
    // Auth
    LOGIN: `${api}/login`,
    REGISTER: `${api}/register`,
    LOGOUT: `${api}/logout`,
    API_EMAIL_SCRAP: `${api}/import-emails`,

    // Report
    API_REPORT_INFO: `${api}/api/report`,
    API_REPORT_INFO_DAILY: `${api}/api/report/daily`,
    API_REPORT_INFO_USER: `${api}/api/report/user`,
    API_REPORT_INFO_SERVER: `${api}/api/report/server`,
    API_REPORT_INFO_MAIL_ACC: `${api}/api/report/mail-acc`,

    API_REPORT_DETAIL: `${api}/api/report/detail`,
    API_REPORT_DETAIL_DEFER: `${api}/api/report/detail/defer`,
    API_REPORT_DETAIL_HASH_DEFER: `${api}/api/report/detail/:hash/defer`,

    // Setting
    SETTING_SERVER_LIST: `${api}/setting/server/`,
    SETTING_SERVER_CREATE: `${api}/setting/server/create`,
    SETTING_SERVER_UPDATE: `${api}/setting/server/update`,
};

export default ConfigApi;
