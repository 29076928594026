import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import HeaderSection from '../../../components/basic/HeaderSection';
import AxiosAuth from '../../../utils/AxiosAuth';
import DropdownMenu from '../../../utils/DropdownMenu';
import Pagination from '../../../utils/Pagination';

function EmailDetailView({ emailDetail, setDetailId }) {
    return (
        <div className="main-panel" style={{ width: '49%' }}>
            <div className="main-panel-header">
                <div className="main-panel-title" style={{ width: '100%' }}>
                    <span style={{ flex: 1 }}>Message Details</span>
                    <button
                        type="button"
                        onClick={() => setDetailId('')}
                        style={{ color: 'white', backgroundColor: 'red' }}
                    >
                        Close
                    </button>
                </div>
            </div>
            <div className="main-panel-body">
                <div className="responsive-body">
                    <div className="report-msg-detail">
                        <h3>Host</h3>
                        <h6>{emailDetail?.host}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>MsgID</h3>
                        <h6>{emailDetail?.msgid}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>Type</h3>
                        <h6>{emailDetail?.type}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>Defer Status & Router</h3>
                        <h6>
                            {emailDetail?.defer_status ? emailDetail?.defer_status : '--'} &&{' '}
                            {emailDetail?.router}
                        </h6>
                    </div>

                    <div className="report-msg-detail">
                        <h3>Sender</h3>
                        <h6>{emailDetail?.sender}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>Spam Score</h3>
                        <h6>{emailDetail?.spamscore}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>Sender Host</h3>
                        <h6>{emailDetail?.senderhost}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>Size</h3>
                        <h6>{emailDetail?.size}</h6>
                    </div>

                    <div className="report-msg-detail">
                        <h3>Email</h3>
                        <h6>{emailDetail?.email}</h6>
                    </div>

                    <div className="report-msg-detail">
                        <h3>Transport Is Remote</h3>
                        <h6>{emailDetail?.transport_is_remote}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>User</h3>
                        <h6>{emailDetail?.user}</h6>
                    </div>

                    <div className="report-msg-detail">
                        <h3>Recipient</h3>
                        <h6>{emailDetail?.recipient}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>Sender Ip</h3>
                        <h6>{emailDetail?.senderip}</h6>
                    </div>

                    <div className="report-msg-detail">
                        <h3>Delivery user</h3>
                        <h6>{emailDetail?.deliveryuser}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>Send Unix Time</h3>
                        <h6>{emailDetail?.sendunixtime}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>Delivery Domain</h3>
                        <h6>{emailDetail?.deliverydomain}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>Sender Auth</h3>
                        <h6>{emailDetail?.senderauth}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>Domain</h3>
                        <h6>{emailDetail?.domain}</h6>
                    </div>

                    <div className="report-msg-detail">
                        <h3>Transport</h3>
                        <h6>{emailDetail?.transport}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>Delivered to</h3>
                        <h6>{emailDetail?.deliveredto}</h6>
                    </div>
                    <div className="report-msg-detail">
                        <h3>Action Unix Time</h3>
                        <h6>{emailDetail?.actionunixtime}</h6>
                    </div>
                    <div className="report-msg-detail" style={{ width: '100%' }}>
                        <h3>Message</h3>
                        <h6>{emailDetail?.message}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}
export function EmailListView({
    emailInfoAr,
    detailId,
    setDetailId,
    isDefer = false,
    totalRows,
    pageNo,
    selectEmail,
    selectServer,
    selectUser,
    startDate,
    endDate,
    type,
    type2,
}) {
    if (emailInfoAr.length < 1) {
        return null;
    }
    return (
        <div className="main-panel-body">
            <div className="scroll-table-container">
                <table
                    className="scroll-table main-panel-body-table w-max-content tbody"
                    style={{ width: '100%' }}
                >
                    <thead className="main-panel-body-table-thead">
                        <tr className="main-panel-head-table-tr">
                            <th className="main-panel-body-table-th ">SL</th>
                            {detailId > 0 ? null : (
                                <>
                                    <th className="main-panel-body-table-th ">MsgId</th>
                                    <th className="main-panel-body-table-th ">Server</th>
                                    <th className="main-panel-body-table-th ">Email</th>
                                </>
                            )}

                            <th className="main-panel-body-table-th ">Sender</th>
                            <th className="main-panel-body-table-th ">Recipient</th>
                            {detailId > 0 ? null : (
                                <>
                                    <th className="main-panel-body-table-th ">Message</th>
                                </>
                            )}
                            <th className="main-panel-body-table-th ">Date & Time</th>
                            {isDefer ? <th className="main-panel-body-table-th ">Total</th> : null}
                        </tr>
                    </thead>
                    <tbody style={{ width: '100%' }}>
                        {Object.values(emailInfoAr)?.map((row, index) => (
                            <tr
                                className={`main-panel-body-table-tr ${
                                    detailId === row.sl ? 'active-tr' : ''
                                }`}
                                key={row.sl}
                                onClick={() => setDetailId(row.sl)}
                                style={{ cursor: 'pointer', width: '100%' }}
                            >
                                <td className="main-panel-body-table-td">
                                    {((pageNo || 1) - 1) * 100 + (index + 1)}
                                </td>

                                {detailId > 0 ? null : (
                                    <>
                                        <td
                                            className="main-panel-body-table-td w-12-percent"
                                            style={{ whiteSpace: 'nowrap' }}
                                        >
                                            <p>{row.msgid}</p>
                                        </td>
                                        <td
                                            className="main-panel-body-table-td w-12-percent"
                                            style={{ whiteSpace: 'nowrap' }}
                                        >
                                            <p>{row.host}</p>
                                        </td>
                                        <td className="main-panel-body-table-td w-12-percent">
                                            <p>{row.email}</p>
                                        </td>
                                    </>
                                )}

                                <td className="main-panel-body-table-td w-12-percent">
                                    <p>{row.sender}</p>
                                </td>
                                <td className="main-panel-body-table-td w-12-percent">
                                    <p>{row.recipient}</p>
                                </td>
                                {detailId > 0 ? null : (
                                    <>
                                        <td className="main-panel-body-table-td w-12-percent">
                                            <p>{row.message}</p>
                                        </td>
                                    </>
                                )}
                                <td className="main-panel-body-table-td w-12-percent">
                                    <p>{row.actionunixtime}</p>
                                </td>
                                {isDefer ? (
                                    <td className="main-panel-body-table-td w-12-percent">
                                        <p>{row.totalDefer}</p>
                                    </td>
                                ) : null}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {isDefer ? null : (
                <Pagination
                    totalRows={totalRows}
                    pageRows={100}
                    pageNo={pageNo}
                    selectEmail={selectEmail}
                    selectServer={selectServer}
                    selectUser={selectUser}
                    startDate={startDate}
                    endDate={endDate}
                    type={type}
                    type2={type2}
                />
            )}
        </div>
    );
}
const headers = [
    { label: 'MessageID', key: 'msgid' },
    { label: 'Host', key: 'host' },
    { label: 'Email', key: 'email' },
    { label: 'Sender', key: 'sender' },
    { label: 'Recipient', key: 'recipient' },
    { label: 'Message', key: 'message' },
    { label: 'Action Unix Time', key: 'actionunixtime' },
];

function ReportDetailInfo() {
    const location = useLocation();
    const navigate = useNavigate();

    const selectEmail = new URLSearchParams(location.search).get('email');
    const selectServer = new URLSearchParams(location.search).get('select_server');
    const selectUser = new URLSearchParams(location.search).get('select_user');
    const startDate = new URLSearchParams(location.search).get('start_date');
    const endDate = new URLSearchParams(location.search).get('end_date');
    const type = new URLSearchParams(location.search).get('type');
    const type2 = new URLSearchParams(location.search).get('type2');
    const page = new URLSearchParams(location.search).get('page');

    const [serverInp, setServerInp] = useState(selectServer || '');
    const [userInp, setUserInp] = useState(selectUser || '');
    const [emailInp, setEmailInp] = useState(selectEmail || '');
    const [startDateInp, setStartDateInp] = useState(startDate || '');
    const [endDateInp, setEndDateInp] = useState(endDate || '');
    const [serverAr, setServerAr] = useState([]);
    const [userAr, setUserAr] = useState([]);
    const [emailInfoAr, setEmailInfoAr] = useState([]);
    const [emailInfoArCsv, setEmailInfoArCsv] = useState([]);
    const [detailId, setDetailId] = useState('');
    const [typeInp, setTypeInp] = useState(type || '');
    const [type2Inp, setType2Inp] = useState(type2 || '');

    const [totalRows, setTotalRows] = useState(0);
    const [pageNo, setPageNo] = useState(1);

    let emailDetail = [];

    const handleInput = (e) => {
        if (e.target.name === 'emailInp') {
            setEmailInp(e.target.value);
        }
        if (e.target.name === 'startDateInp') {
            setStartDateInp(e.target.value);
        }
        if (e.target.name === 'endDateInp') {
            setEndDateInp(e.target.value);
        }
    };
    const handleSearch = () => {
        navigate(
            `/email/report/details/?type=${typeInp || ''}&type2=${type2Inp || ''}&select_server=${
                serverInp || ''
            }&select_user=${userInp || ''}&email=${emailInp || ''}&start_date=${
                startDateInp || ''
            }&end_date=${endDateInp || ''}&page=${pageNo}`
        );
    };
    const csvReport = {
        data: Object.values(emailInfoArCsv),
        headers,
        filename: 'report_detail.csv',
    };
    console.log(emailInfoArCsv);
    useEffect(() => {
        setPageNo(page || 1);

        AxiosAuth.get(
            `${ConfigApi.API_REPORT_DETAIL}?type=${type || ''}&type2=${type2 || ''}&select_server=${
                selectServer || ''
            }&select_user=${selectUser || ''}&email=${selectEmail || ''}&start_date=${
                startDate || ''
            }&end_date=${endDate || ''}&page=${pageNo}`
        ).then((response) => {
            setServerAr(response.data.serverAr);
            setUserAr(response.data.userAr);
            setEmailInfoAr(response.data.emailInfoAr);
            setTotalRows(response.data.totalEmailInfoAr);
            setEmailInfoArCsv(response.data.emailInfoArAll);
            setStartDateInp(response.data.timeStart);
            setEndDateInp(response.data.timeEnd);
        });
    }, [
        emailInp,
        endDate,
        endDateInp,
        location.search,
        page,
        pageNo,
        selectEmail,
        selectServer,
        selectUser,
        serverInp,
        startDate,
        startDateInp,
        type,
        type2,
        userInp,
    ]);

    if (detailId > 0) {
        emailDetail = Object.values(emailInfoAr)?.filter((rowFil) => rowFil?.sl === detailId)[0];
    }
    return (
        <>
            <HeaderSection csvReport={csvReport} />
            <div className="main-panel" style={{ width: detailId > 0 ? '49%' : '100%' }}>
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <DropdownMenu
                                selectedOption={typeInp}
                                setSelectedOption={setTypeInp}
                                options={['success', 'failure', 'inprogress', 'rejected']}
                                width="100%"
                                defaultOption="Select Type"
                                isInput={false}
                            />
                        </div>
                        {detailId > 0 ? null : (
                            <>
                                <div className="sidebar-panel-search">
                                    <DropdownMenu
                                        selectedOption={type2Inp}
                                        setSelectedOption={setType2Inp}
                                        options={['in', 'out']}
                                        width="100%"
                                        defaultOption="Select Delivery Type"
                                        isInput={false}
                                    />
                                </div>
                            </>
                        )}
                        <div className="sidebar-panel-search">
                            <DropdownMenu
                                selectedOption={serverInp}
                                setSelectedOption={setServerInp}
                                options={serverAr ? Object.values(serverAr) : []}
                                width="100%"
                                defaultOption="Select Server"
                            />
                        </div>
                        <div className="sidebar-panel-search">
                            <DropdownMenu
                                selectedOption={userInp}
                                setSelectedOption={setUserInp}
                                options={userAr ? Object.values(userAr) : []}
                                width="100%"
                                defaultOption="Select User"
                            />
                        </div>
                        {detailId > 0 ? null : (
                            <>
                                <div className="sidebar-panel-search">
                                    <input
                                        type="text"
                                        name="emailInp"
                                        placeholder="Email"
                                        value={emailInp}
                                        onChange={handleInput}
                                    />
                                </div>
                                <div className="sidebar-panel-search">
                                    <input
                                        type="date"
                                        name="startDateInp"
                                        value={startDateInp}
                                        onChange={handleInput}
                                    />
                                </div>
                                <div className="sidebar-panel-search">
                                    <input
                                        type="date"
                                        name="endDateInp"
                                        value={endDateInp}
                                        onChange={handleInput}
                                    />
                                </div>
                            </>
                        )}
                        <button type="button" onClick={handleSearch}>
                            Search
                        </button>
                    </form>
                </div>
                <EmailListView
                    emailInfoAr={emailInfoAr || []}
                    detailId={detailId}
                    setDetailId={setDetailId}
                    totalRows={totalRows}
                    pageNo={pageNo}
                    selectEmail={selectEmail}
                    selectServer={selectServer}
                    selectUser={selectUser}
                    startDate={startDate}
                    endDate={endDate}
                    type={type}
                    type2={type2}
                />
            </div>
            {detailId > 0 ? (
                <EmailDetailView emailDetail={emailDetail} setDetailId={setDetailId} />
            ) : null}
        </>
    );
}

export default ReportDetailInfo;
