import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IconCross from '../../../../assets/images/IconCross.svg';
import ConfigApi from '../../../../configs/ConfigApi';
import HeaderSection from '../../../components/basic/HeaderSection';
import AxiosAuth from '../../../utils/AxiosAuth';
import DropdownMenu from '../../../utils/DropdownMenu';
import ModelLink from '../models/ModelLink';

function ReportUser() {
    const location = useLocation();
    const navigate = useNavigate();

    const selectEmail = new URLSearchParams(location.search).get('email');
    const selectServer = new URLSearchParams(location.search).get('select_server');
    const selectUser = new URLSearchParams(location.search).get('select_user');
    const startDate = new URLSearchParams(location.search).get('start_date');
    const endDate = new URLSearchParams(location.search).get('end_date');

    const [serverInp, setServerInp] = useState(selectServer || '');
    const [userInp, setUserInp] = useState(selectUser || '');
    const [emailInp, setEmailInp] = useState(selectEmail || '');
    const [startDateInp, setStartDateInp] = useState(startDate || '');
    const [endDateInp, setEndDateInp] = useState(endDate || '');

    const [serverAr, setServerAr] = useState([]);
    const [userAr, setUserAr] = useState([]);
    const [totalAr, setTotalAr] = useState([]);
    const [totalSent, setTotalSent] = useState(0);
    const [totalReceive, setTotalReceive] = useState(0);
    const [bouncePercent, setBouncePercent] = useState(0);

    const handleInput = (e) => {
        if (e.target.name === 'email') {
            setEmailInp(e.target.value);
        }
        if (e.target.name === 'start_date') {
            setStartDateInp(e.target.value);
        }
        if (e.target.name === 'end_date') {
            setEndDateInp(e.target.value);
        }
    };
    const handleSearchClear = () => {
        setServerInp('');
        setUserInp('');
        setEmailInp('');
        setStartDateInp(new Date().toISOString().slice(0, 10));
        setEndDateInp(new Date().toISOString().slice(0, 10));
        navigate(`/email/report/daily/`);
    };

    const handleSearch = () => {
        navigate(
            `/email/report/user/?select_server=${serverInp || ''}&select_user=${
                userInp || ''
            }&email=${emailInp || ''}&start_date=${startDateInp || ''}&end_date=${endDateInp || ''}`
        );
    };

    const handlePrint = () => {
        navigate(
            `/email/report/print/?select_server=${serverInp || ''}&select_user=${
                userInp || ''
            }&email=${emailInp || ''}&start_date=${startDateInp || ''}&end_date=${endDateInp || ''}`
        );
    };
    useEffect(() => {
        AxiosAuth.get(
            `${ConfigApi.API_REPORT_INFO_USER}?select_server=${selectServer || ''}&select_user=${
                selectUser || ''
            }&email=${selectEmail || ''}&start_date=${startDate || ''}&end_date=${endDate || ''}`
        ).then((response) => {
            setServerAr(response.data.serverAr);
            setUserAr(response.data.userAr);
            setTotalAr(response.data.totalAr);
            setTotalSent(response.data.totalSent);
            setTotalReceive(response.data.totalReceived);
            setBouncePercent(response.data.bouncePercent);
            setStartDateInp(response.data.timeStart);
            setEndDateInp(response.data.timeEnd);
        });
    }, [endDate, selectEmail, selectServer, selectUser, startDate]);

    return (
        <>
            <HeaderSection />
            <div className="main-panel">
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <input type="hidden" name="select_server" value={serverInp} />
                            <DropdownMenu
                                selectedOption={serverInp}
                                setSelectedOption={setServerInp}
                                options={serverAr ? Object.values(serverAr) : []}
                                width="100%"
                                defaultOption="Select Server"
                            />
                        </div>
                        <div className="sidebar-panel-search">
                            <input type="hidden" name="select_user" value={userInp} />
                            <DropdownMenu
                                selectedOption={userInp}
                                setSelectedOption={setUserInp}
                                options={userAr ? Object.values(userAr) : []}
                                width="100%"
                                defaultOption="Select User"
                            />
                        </div>
                        <div className="sidebar-panel-search">
                            <input
                                type="text"
                                name="email"
                                placeholder="Email"
                                value={emailInp}
                                onChange={handleInput}
                            />
                        </div>
                        <div className="sidebar-panel-search">
                            <input
                                type="date"
                                name="start_date"
                                value={startDateInp}
                                onChange={handleInput}
                            />
                        </div>
                        <div className="sidebar-panel-search">
                            <input
                                type="date"
                                name="end_date"
                                value={endDateInp}
                                onChange={handleInput}
                            />
                        </div>

                        <button type="button" onClick={handleSearch}>
                            Search
                        </button>
                        {selectEmail || selectServer || selectUser || startDate || endDate ? (
                            <button type="button" className="cross-btn" onClick={handleSearchClear}>
                                <img src={IconCross} alt="Cross icon" />
                            </button>
                        ) : null}
                    </form>
                    <button type="button" onClick={handlePrint}>
                        Print
                    </button>
                </div>
                <div className="main-panel-body">
                    <table className="main-panel-body-table mb-3">
                        <thead className="main-panel-body-table-thead">
                            <tr className="main-panel-head-table-tr">
                                <th
                                    className="main-panel-body-table-th"
                                    rowSpan={2}
                                    style={{
                                        verticalAlign: 'bottom',
                                        paddingBottom: '20px',
                                        borderRight: '1px solid #D0CFD9',
                                    }}
                                >
                                    User
                                </th>
                                <th
                                    className="main-panel-body-table-th text-center"
                                    colSpan={5}
                                    style={{ borderRight: '1px solid #D0CFD9' }}
                                >
                                    Incoming
                                </th>
                                <th className="main-panel-body-table-th text-center" colSpan={7}>
                                    Outgoing
                                </th>
                                <th rowSpan={2} />
                            </tr>
                            <tr className="main-panel-head-table-tr">
                                <td className="main-panel-body-table-th">Success</td>
                                <td className="main-panel-body-table-th">InProgress</td>
                                <td className="main-panel-body-table-th">Reject</td>
                                <td className="main-panel-body-table-th">Failure</td>
                                <td
                                    className="main-panel-body-table-th"
                                    style={{ borderRight: '1px solid #D0CFD9' }}
                                >
                                    Total Receive
                                </td>
                                <td className="main-panel-body-table-th">Success</td>
                                <td className="main-panel-body-table-th">InProgress</td>
                                <td className="main-panel-body-table-th">Reject</td>
                                <td className="main-panel-body-table-th">Failure</td>
                                <td className="main-panel-body-table-th">Defer</td>
                                <td className="main-panel-body-table-th">Total Sent</td>
                                <td className="main-panel-body-table-th">Bounce rate</td>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(userAr)?.map((row) => (
                                <tr
                                    className="main-panel-body-table-tr"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <td style={{ borderRight: '1px solid #D0CFD9' }}>{row}</td>
                                    <td className="text-center">
                                        {totalAr?.Success?.Incoming[row] ? (
                                            <ModelLink
                                                display={totalAr?.Success?.Incoming[row]}
                                                type="success"
                                                type2="in"
                                                selectServer={serverInp || null}
                                                selectUser={row}
                                                email={emailInp || null}
                                                startDate={startDate}
                                                endDate={endDate}
                                            />
                                        ) : (
                                            0
                                        )}
                                    </td>
                                    <td className="text-center">
                                        {totalAr?.InProgress?.Incoming[row] ? (
                                            <ModelLink
                                                display={totalAr?.InProgress?.Incoming[row]}
                                                type="inprogress"
                                                type2="in"
                                                selectServer={serverInp || null}
                                                selectUser={row}
                                                email={emailInp || null}
                                                startDate={startDate}
                                                endDate={endDate}
                                            />
                                        ) : (
                                            0
                                        )}
                                    </td>
                                    <td className="text-center">
                                        {totalAr?.Reject?.Incoming[row] ? (
                                            <ModelLink
                                                display={totalAr?.Reject?.Incoming[row]}
                                                type="rejected"
                                                type2="in"
                                                selectServer={serverInp || null}
                                                selectUser={row}
                                                email={emailInp || null}
                                                startDate={startDate}
                                                endDate={endDate}
                                            />
                                        ) : (
                                            0
                                        )}
                                    </td>
                                    <td className="text-center">
                                        {totalAr?.Failure?.Incoming[row] ? (
                                            <ModelLink
                                                display={totalAr?.Failure?.Incoming[row]}
                                                type="failure"
                                                type2="in"
                                                selectServer={serverInp || null}
                                                selectUser={row}
                                                email={emailInp || null}
                                                startDate={startDate}
                                                endDate={endDate}
                                            />
                                        ) : (
                                            0
                                        )}
                                    </td>

                                    <td style={{ borderRight: '1px solid #D0CFD9' }}>
                                        {totalReceive[row] ? totalReceive[row] : 0}
                                    </td>
                                    <td className="text-center">
                                        {totalAr?.Success?.Outgoing[row] ? (
                                            <ModelLink
                                                display={totalAr?.Success.Outgoing[row]}
                                                type="success"
                                                type2="out"
                                                selectServer={serverInp || null}
                                                selectUser={row}
                                                email={emailInp || null}
                                                startDate={startDate}
                                                endDate={endDate}
                                            />
                                        ) : (
                                            0
                                        )}
                                    </td>
                                    <td className="text-center">
                                        {totalAr?.InProgress?.Outgoing[row] ? (
                                            <ModelLink
                                                display={totalAr?.InProgress.Outgoing[row]}
                                                type="inprogress"
                                                type2="out"
                                                selectServer={serverInp || null}
                                                selectUser={row}
                                                email={emailInp || null}
                                                startDate={startDate}
                                                endDate={endDate}
                                            />
                                        ) : (
                                            0
                                        )}
                                    </td>
                                    <td className="text-center">
                                        {totalAr?.Reject?.Outgoing[row] ? (
                                            <ModelLink
                                                display={totalAr?.Reject.Outgoing[row]}
                                                type="rejected"
                                                type2="out"
                                                selectServer={serverInp || null}
                                                selectUser={row}
                                                email={emailInp || null}
                                                startDate={startDate}
                                                endDate={endDate}
                                            />
                                        ) : (
                                            0
                                        )}
                                    </td>
                                    <td className="text-center">
                                        {totalAr?.Failure?.Outgoing[row] ? (
                                            <ModelLink
                                                display={totalAr?.Failure.Outgoing[row]}
                                                type="failure"
                                                type2="out"
                                                selectServer={serverInp || null}
                                                selectUser={row}
                                                email={emailInp || null}
                                                startDate={startDate}
                                                endDate={endDate}
                                            />
                                        ) : (
                                            0
                                        )}
                                    </td>
                                    <td className="text-center">
                                        {totalAr?.Defer?.Outgoing[row] ? (
                                            <ModelLink
                                                display={totalAr?.Defer.Outgoing[row]}
                                                type="defer"
                                                type2="out"
                                                selectServer={serverInp || null}
                                                selectUser={row}
                                                email={emailInp || null}
                                                startDate={startDate}
                                                endDate={endDate}
                                            />
                                        ) : (
                                            0
                                        )}
                                    </td>
                                    <td className="text-center">
                                        {totalSent[row] ? totalSent[row] : 0}
                                    </td>
                                    <td className="text-center">
                                        {bouncePercent[row] ? bouncePercent[row] : 0} %
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default ReportUser;
