import React, { useEffect, useRef, useState } from 'react';

function SelectedOption({ toggling, isOpen, selectedOption, defaultOption }) {
    return (
        <div
            role="button"
            tabIndex={-1}
            style={{ cursor: 'default' }}
            onClick={toggling}
            className={`dropdown-btn ${isOpen ? 'active' : 'inactive'}`}
        >
            {selectedOption || defaultOption}
        </div>
    );
}

function Options({ handleSelectedOption, isOpen, options, property, variant, isInput }) {
    const [searchText, setSearchText] = useState('');
    const arr =
        searchText.length > 0
            ? options?.filter((data) => data?.toLowerCase().includes(searchText.toLowerCase()))
            : options;

    const focusInput = useRef();

    useEffect(() => {
        if (isInput) {
            focusInput.current.focus();
        }
    }, [isInput]);
    return (
        <div
            className={`dropdown-options w-100 ${isOpen ? 'active' : ''}`}
            style={{ top: variant === 'border' ? '50px' : `${isInput ? '0' : '40px'}` }}
        >
            {isInput ? (
                <input
                    onChange={(e) => setSearchText(e.target.value)}
                    type="text"
                    className="form-input"
                    placeholder="Search...."
                    ref={focusInput}
                />
            ) : null}
            {arr.map((item) => (
                <div
                    key={Math.random()}
                    role="button"
                    tabIndex={-1}
                    onClick={() => handleSelectedOption(item)}
                    className="option-item"
                >
                    <span className="no-wrap">{property ? item[property] : item}</span>{' '}
                </div>
            ))}
        </div>
    );
}

// need to pass state = (selectedOption, setSelectedOption) and array of options
// selectedOption state will store the value of clicked option
function DropdownMenu({
    defaultOption = 'Select',
    selectedOption,
    setSelectedOption,
    options,
    property,
    variant = '',
    width,
    height,
    isInput = true,
}) {
    const [isOpen, setIsOpen] = useState(false);

    const myRef = useRef();

    // option open/close
    const toggling = () => setIsOpen(!isOpen);

    // get value and close options
    const handleSelectedOption = (value) => {
        setSelectedOption(value);
        setIsOpen(false);
    };

    // close options when click outside

    useEffect(() => {
        function handelClickOutside(event) {
            if (myRef?.current && !myRef?.current.contains(event?.target)) {
                // alert("You clicked outside of me!");
                setIsOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, []);

    return (
        <div
            className="dropdown-area"
            ref={myRef}
            style={
                variant === 'border'
                    ? {
                          border: '1px solid #130f4033',
                          borderRadius: '8px',
                          width: width || '150px',
                          height: height || '40px',
                      }
                    : { width: width || '150px' }
            }
        >
            <SelectedOption
                isOpen={isOpen}
                toggling={toggling}
                selectedOption={selectedOption}
                defaultOption={defaultOption}
            />
            {isOpen ? (
                <Options
                    handleSelectedOption={handleSelectedOption}
                    isOpen={isOpen}
                    options={options}
                    variant={variant}
                    property={property}
                    selectedOption={selectedOption}
                    isInput={isInput}
                />
            ) : null}
        </div>
    );
}

export default DropdownMenu;
