/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import EditIcon from '../../../../assets/images/editicon.svg';
import ConfigApi from '../../../../configs/ConfigApi';
import HeaderSection from '../../../components/basic/HeaderSection';
import SidebarSection from '../../../components/basic/SidebarSection';
import AxiosAuth from '../../../utils/AxiosAuth';
import ModalServerContract from '../modals/ModalServerContract';

function ServerSettingPage() {
    const [serverAr, setServerAr] = useState([]);
    const [showNew, setShowNew] = useState(false);
    const [selectId, setSelectId] = useState('');

    const handleClose = () => {
        setShowNew(false);
    };
    const handleUpdate = (e) => {
        setSelectId(e);
        setShowNew(true);
    };
    const handleAdd = () => {
        setSelectId('');
        setShowNew(true);
    };
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.SETTING_SERVER_LIST}`).then((response) => {
            setServerAr(response.data.dataAr);
        });
    }, [showNew]);

    return (
        <div className="content">
            <SidebarSection />
            <div className="main-stricture">
                <HeaderSection />
                <div className="main-panel">
                    <div className="main-panel-header">
                        <form method="get" action="" className="main-panel-header-search" />
                        <button type="button" onClick={handleAdd}>
                            Add New Contact
                        </button>
                    </div>
                    <div className="main-panel-body">
                        <table className="main-panel-body-table mb-3">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>User</th>
                                    <th>Server</th>
                                    <th>IP Primary</th>
                                    <th>Location</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {serverAr?.map((row) => (
                                    <tr key={row?.sl}>
                                        <td>H{row?.sl}</td>
                                        <td>{row?.username}</td>
                                        <td>{row?.host}</td>
                                        <td>{row?.ip_primary}</td>
                                        <td>{row?.location}</td>
                                        <td>{row?.status}</td>
                                        <td className="action-area">
                                            <button
                                                type="button"
                                                onClick={() => handleUpdate(row?.sl)}
                                            >
                                                <img src={EditIcon} alt="Edit" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {showNew ? (
                        <ModalServerContract
                            serverAr={serverAr?.filter((r) => r.sl === selectId)[0]}
                            show={showNew}
                            handleClose={handleClose}
                            selectId={selectId}
                            setSelectId={setSelectId}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default ServerSettingPage;
