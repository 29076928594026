/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';

function LoginPage() {
    const [userName, setUserName] = useState('');
    const [password, setpassword] = useState('');
    const navigate = useNavigate();
    const handleUserName = (e) => {
        setUserName(e.target.value);
    };
    const handelPassword = (e) => {
        setpassword(e.target.value);
    };
    const handleLogin = () => {
        AxiosAuth.post(ConfigApi.LOGIN, { email: userName, password })
            .then((response) => {
                // --Adding Secure Token
                if (response.headers['secure-access']) {
                    localStorage.setItem('Secure-Access', response.headers['secure-access']);
                }
                if (response.data.error === 0) {
                    navigate('/email/report/');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        if (localStorage.getItem('Secure-Access')) {
            navigate('/email/report/');
        }
    });

    return (
        <div className="content">
            <div className="login-section">
                <div className="login-section-title">User Login</div>
                <form
                    className="login-section-body"
                    action=""
                    method="post"
                    encType="multipart/form-data"
                    id="form"
                >
                    <div className="row">
                        <div className="col-12 login-section-input">
                            <label>Email Address</label>
                            <input
                                type="email"
                                name="email"
                                id="inputEmail"
                                className="form-control"
                                placeholder="EX: abcdefg@email.com"
                                aria-label=""
                                value={userName}
                                onChange={handleUserName}
                            />
                        </div>
                        <div className="col-12 login-section-input">
                            <label>Password</label>
                            <input
                                type="password"
                                name="password"
                                className="form-control"
                                id="inputPassword"
                                placeholder="Enter Password"
                                aria-label=""
                                value={password}
                                onChange={handelPassword}
                                onKeyDown={handleLogin}
                            />
                        </div>
                        <div className="col-12">
                            <div className="login-section-forgot mb-2">
                                <a href="/">Forgot Password?</a>
                            </div>
                        </div>
                        <div className="col-12">
                            <button
                                type="button"
                                className="login-section-sign"
                                onClick={handleLogin}
                            >
                                Sign In
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default LoginPage;
