/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Pagination({
    totalRows,
    pageRows,
    pageNo,
    selectEmail,
    selectServer,
    selectUser,
    startDate,
    endDate,
    type,
    type2,
}) {
    const { pathname } = useLocation();

    const numberOfPage = Math.ceil(totalRows / pageRows);
    const liAr = [];

    for (let index = 1; index <= (numberOfPage > 0 ? numberOfPage : 1); index += 1) {
        liAr.push(
            <li
                className={`paginate_button page-item ${
                    (pageNo || 1)?.toString() === index?.toString() ? 'active' : null
                }`}
            >
                <Link
                    to={`${pathname}?type=${type || ''}&type2=${type2 || ''}&select_server=${
                        selectServer || ''
                    }&select_user=${selectUser || ''}&email=${selectEmail || ''}&start_date=${
                        startDate || ''
                    }&end_date=${endDate || ''}&page=${index}`}
                    className="page-link"
                >
                    {index}
                </Link>
            </li>
        );
    }
    const liAr2 = [];

    const addDot = (
        <li
            className={`paginate_button page-item ${
                (pageNo || 1)?.toString() === 4?.toString() ? 'active' : null
            }`}
        >
            <Link
                to={`${pathname}?type=${type || ''}&type2=${type2 || ''}&select_server=${
                    selectServer || ''
                }&select_user=${selectUser || ''}&email=${selectEmail || ''}&start_date=${
                    startDate || ''
                }&end_date=${endDate || ''}&page=${4}`}
                className="page-link"
            >
                ...
            </Link>
        </li>
    );
    const addFirst = (
        <li
            className={`paginate_button page-item ${
                (pageNo || 1)?.toString() === 4?.toString() ? 'active' : null
            }`}
        >
            <Link
                to={`${pathname}?type=${type || ''}&type2=${type2 || ''}&select_server=${
                    selectServer || ''
                }&select_user=${selectUser || ''}&email=${selectEmail || ''}&start_date=${
                    startDate || ''
                }&end_date=${endDate || ''}&page=${1}`}
                className="page-link"
            >
                1
            </Link>
        </li>
    );
    if (numberOfPage > 9) {
        Object.values(liAr)?.map((row, rowIndex) => {
            if (rowIndex < parseInt(pageNo, 10) + 3 || rowIndex > parseInt(liAr.length, 10) - 4) {
                if (pageNo > 2) {
                    if (rowIndex > parseInt(pageNo, 10) - 3) {
                        return liAr2.push(row);
                    }
                } else {
                    return liAr2.push(row);
                }
            }
            return '';
        });
        if (pageNo > 2) {
            liAr2.splice(0, 0, addFirst);
        }
        if (pageNo > numberOfPage - 4) {
            liAr2.splice(1, 0, addDot);
        } else {
            liAr2.splice(4, 0, addDot);
        }
    }
    return (
        <ul className="pagination">
            <li
                className={`paginate_button page-item previous  ${
                    pageNo?.toString() === '1' ? 'disabled' : null
                }`}
            >
                <Link
                    to={`${pathname}?type=${type || ''}&type2=${type2 || ''}&select_server=${
                        selectServer || ''
                    }&select_user=${selectUser || ''}&email=${selectEmail || ''}&start_date=${
                        startDate || ''
                    }&end_date=${endDate || ''}&page=${parseInt(pageNo, 10) - 1}`}
                    className="page-link"
                >
                    &laquo;
                </Link>
            </li>
            {Object.values(liAr2.length > 0 ? liAr2 : liAr)?.map((row) => row)}
            <li
                className={`paginate_button page-item previous  ${
                    pageNo?.toString() === numberOfPage?.toString() ? 'disabled' : null
                }`}
            >
                <Link
                    to={`${pathname}?type=${type || ''}&type2=${type2 || ''}&select_server=${
                        selectServer || ''
                    }&select_user=${selectUser || ''}&email=${selectEmail || ''}&start_date=${
                        startDate || ''
                    }&end_date=${endDate || ''}&page=${parseInt(pageNo, 10) + 1}`}
                    className="page-link"
                >
                    &raquo;
                </Link>
            </li>
        </ul>
    );
}

export default Pagination;
