import React from 'react';
import { Link } from 'react-router-dom';

function ModelLink({ display, type, type2, selectServer, selectUser, email, startDate, endDate }) {
    if (type === 'defer') {
        return (
            <Link
                target="_blank"
                to={`/email/report/details/defer/?type2=${type2 || ''}&select_server=${
                    selectServer || ''
                }&select_user=${selectUser || ''}&email=${email || ''}&start_date=${
                    startDate || ''
                }&end_date=${endDate || ''}`}
            >
                {display}
            </Link>
        );
    }
    return (
        <Link
            target="_blank"
            to={`/email/report/details/?type=${type || ''}&type2=${type2 || ''}&select_server=${
                selectServer || ''
            }&select_user=${selectUser || ''}&email=${email || ''}&start_date=${
                startDate || ''
            }&end_date=${endDate || ''}`}
        >
            {display}
        </Link>
    );
}

export default ModelLink;
