import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReportDaily from './app/reports/pages/ReportDaily';
import ReportDetailDeferInfo from './app/reports/pages/ReportDetailDeferInfo';
import ReportDetailInfo from './app/reports/pages/ReportDetailInfo';
import ReportInfo from './app/reports/pages/ReportInfo';
import ReportInfoPrint from './app/reports/pages/ReportInfoPrint';
import ReportMailAcc from './app/reports/pages/ReportMailAcc';
import ReportServer from './app/reports/pages/ReportServer';
import ReportUser from './app/reports/pages/ReportUser';
import ManagesHandler from './app/system/models/ManagesHandler';
import LoginPage from './app/system/pages/LoginPage';
import LogoutPage from './app/system/pages/LogoutPage';
import ServerSettingPage from './app/system/pages/ServerSettingPage';
import AppInitDispatch from './dispatches/AppInitDispatch';
import InitInfo from './utils/InitInfo';

export default function RouteHandler() {
    const dispatch = useDispatch();
    const { initData } = InitInfo();
    const [show, setShow] = useState(!!initData?.initId);

    useEffect(() => {
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    if (!show) {
        return null;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/logout/" element={<LogoutPage />} />

                <Route path="/email/*" element={<ManagesHandler />}>
                    <Route path="report/" element={<ReportInfo />} />
                    <Route path="report/daily/" element={<ReportDaily />} />
                    <Route path="report/user/" element={<ReportUser />} />
                    <Route path="report/server/" element={<ReportServer />} />
                    <Route path="report/mail-account/" element={<ReportMailAcc />} />
                    <Route path="report/details/" element={<ReportDetailInfo />} />
                    <Route path="report/details/defer/" element={<ReportDetailDeferInfo />} />
                </Route>
                <Route path="/email/report/print/" element={<ReportInfoPrint />} />
                <Route path="/setting/server/" element={<ServerSettingPage />} />
            </Routes>
        </BrowserRouter>
    );
}
