import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';

function ReportInfoPrint() {
    const location = useLocation();

    const selectEmail = new URLSearchParams(location.search).get('email');
    const selectServer = new URLSearchParams(location.search).get('select_server');
    const selectUser = new URLSearchParams(location.search).get('select_user');
    const startDate = new URLSearchParams(location.search).get('start_date');
    const endDate = new URLSearchParams(location.search).get('end_date');

    const [serverInp] = useState(selectServer || '');
    const [userInp] = useState(selectUser || '');
    const [emailInp] = useState(selectEmail || '');
    const [startDateInp] = useState(startDate || '');
    const [endDateInp] = useState(endDate || '');

    const [totalAr, setTotalAr] = useState([]);
    const [totalSent, setTotalSent] = useState(0);
    const [totalReceive, setTotalReceive] = useState(0);
    const [bouncePercent, setBouncePercent] = useState(0);

    useEffect(() => {
        AxiosAuth.get(
            `${ConfigApi.API_REPORT_INFO}?select_server=${selectServer || ''}&select_user=${
                selectUser || ''
            }&email=${selectEmail || ''}&start_date=${startDate || ''}&end_date=${endDate || ''}`
        ).then((response) => {
            setTotalAr(response.data.totalAr);
            setTotalSent(response.data.totalSent);
            setTotalReceive(response.data.totalReceive);
            setBouncePercent(response.data.bouncePercent);
        });
    }, [endDate, selectEmail, selectServer, selectUser, startDate]);

    return (
        <>
            <div className="print-body">
                <div id="logo">
                    <img src="https://push.bikiran.com/assets/images/bikiran-banner.png" alt="" />
                </div>
                <div id="company">
                    <h1>Email Info</h1>
                </div>

                <div className="main-panel">
                    <div className="main-panel-body">
                        {totalAr ? (
                            <div className="main-panel-report">
                                <div style={{ width: '100%', marginBottom: 10 }}>
                                    <span>{selectServer ? `Server: ${selectServer} ` : ''}</span>
                                    <span>
                                        {selectUser
                                            ? `${
                                                  selectServer && selectUser ? ',' : ''
                                              } User: ${selectUser} `
                                            : ''}
                                    </span>
                                    <span>
                                        {selectEmail
                                            ? `${
                                                  selectUser && selectEmail ? ',' : ''
                                              } Email: ${selectEmail}`
                                            : ''}
                                    </span>

                                    <span>
                                        {startDate
                                            ? `${
                                                  selectEmail && startDate ? ',' : ''
                                              } Date Range: ${startDate} to ${endDate} `
                                            : ''}
                                    </span>
                                </div>
                                <table className="main-panel-body-table mb-3">
                                    <thead className="main-panel-body-table-thead">
                                        <tr className="main-panel-head-table-tr">
                                            <th className="main-panel-body-table-th " colSpan="3">
                                                Incoming
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="main-panel-body-table-tr">
                                            <td className="main-panel-body-table-td">
                                                Total Received
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                {totalReceive}
                                            </td>
                                            <td className="main-panel-body-table-td" />
                                        </tr>
                                        <tr className="main-panel-body-table-tr">
                                            <td className="main-panel-body-table-td">
                                                Total Success
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                <Link
                                                    to={`/email/report/details?type=success&type2=in&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                                >
                                                    {totalAr?.Success?.Incoming}
                                                </Link>
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                {totalAr?.Success?.InPercent} %
                                            </td>
                                        </tr>

                                        <tr className="main-panel-body-table-tr">
                                            <td className="main-panel-body-table-td">
                                                Total InProgress
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                <Link
                                                    to={`/email/report/details?type=inprogress&type2=in&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                                >
                                                    {totalAr?.InProgress?.Incoming}
                                                </Link>
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                {totalAr?.InProgress?.InPercent} %
                                            </td>
                                        </tr>

                                        <tr className="main-panel-body-table-tr">
                                            <td className="main-panel-body-table-td">
                                                Total Reject
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                <Link
                                                    to={`/email/report/details?type=rejected&type2=in&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                                >
                                                    {totalAr?.Reject?.Incoming}
                                                </Link>
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                {totalAr?.Reject?.InPercent} %
                                            </td>
                                        </tr>

                                        <tr className="main-panel-body-table-tr">
                                            <td className="main-panel-body-table-td">
                                                Total Failure
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                <Link
                                                    to={`/email/report/details?type=failure&type2=in&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                                >
                                                    {totalAr?.Failure?.Incoming}
                                                </Link>
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                {totalAr?.Failure?.InPercent} %
                                            </td>
                                        </tr>
                                        <tr className="main-panel-body-table-tr">
                                            <td className="main-panel-body-table-td">
                                                Total Defer
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                <Link
                                                    to={`/email/report/details/defer/?type2=in&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                                >
                                                    {totalAr?.Defer?.Incoming}
                                                </Link>
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                {totalAr?.Defer?.InPercent} %
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="main-panel-body-table mb-3">
                                    <thead className="main-panel-body-table-thead">
                                        <tr className="main-panel-head-table-tr">
                                            <th className="main-panel-body-table-th " colSpan="3">
                                                Outgoing
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="main-panel-body-table-tr">
                                            <td className="main-panel-body-table-td">Total Sent</td>
                                            <td className="main-panel-body-table-td">
                                                {totalSent}
                                            </td>
                                            <td className="main-panel-body-table-td" />
                                        </tr>

                                        <tr className="main-panel-body-table-tr">
                                            <td className="main-panel-body-table-td">
                                                Total Success
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                <Link
                                                    to={`/email/report/details?type=success&type2=out&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                                >
                                                    {totalAr?.Success?.Outgoing}
                                                </Link>
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                {totalAr?.Success?.OutPercent} %
                                            </td>
                                        </tr>

                                        <tr className="main-panel-body-table-tr">
                                            <td className="main-panel-body-table-td">
                                                Total InProgress
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                <Link
                                                    to={`/email/report/details?type=inprogress&type2=out&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                                >
                                                    {totalAr?.InProgress?.Outgoing}
                                                </Link>
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                {totalAr?.InProgress?.OutPercent} %
                                            </td>
                                        </tr>

                                        <tr className="main-panel-body-table-tr">
                                            <td className="main-panel-body-table-td">
                                                Total Reject
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                <Link
                                                    to={`/email/report/details?type=rejected&type2=out&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                                >
                                                    {totalAr?.Reject?.Outgoing}
                                                </Link>
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                {totalAr?.Reject?.OutPercent} %
                                            </td>
                                        </tr>

                                        <tr className="main-panel-body-table-tr">
                                            <td className="main-panel-body-table-td">
                                                Total Failure
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                <Link
                                                    to={`/email/report/details?type=failure&type2=out&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                                >
                                                    {totalAr?.Failure?.Outgoing}
                                                </Link>
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                {totalAr?.Failure?.OutPercent} %
                                            </td>
                                        </tr>
                                        <tr className="main-panel-body-table-tr">
                                            <td className="main-panel-body-table-td">
                                                Total Defer
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                <Link
                                                    to={`/email/report/details/defer/?type2=out&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                                >
                                                    {totalAr?.Defer?.Outgoing}
                                                </Link>
                                            </td>
                                            <td className="main-panel-body-table-td">
                                                {totalAr?.Defer?.OutPercent} %
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table
                                    className="main-panel-body-table mb-3"
                                    style={{ width: '30%' }}
                                >
                                    <thead className="main-panel-body-table-thead">
                                        <tr className="main-panel-head-table-tr">
                                            <th className="main-panel-body-table-th">
                                                Bounce Rate
                                            </th>
                                            <th className="main-panel-body-table-th">
                                                {bouncePercent} %
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        ) : (
                            'No Data Found'
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportInfoPrint;
