/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import IconCross from '../../../../assets/images/IconCross.svg';
import ConfigApi from '../../../../configs/ConfigApi';
import HeaderSection from '../../../components/basic/HeaderSection';
import AxiosAuth from '../../../utils/AxiosAuth';
import DropdownMenu from '../../../utils/DropdownMenu';

function ReportInfo() {
    const location = useLocation();
    const navigate = useNavigate();

    const selectEmail = new URLSearchParams(location.search).get('email');
    const selectServer = new URLSearchParams(location.search).get('select_server');
    const selectUser = new URLSearchParams(location.search).get('select_user');
    const startDate = new URLSearchParams(location.search).get('start_date');
    const endDate = new URLSearchParams(location.search).get('end_date');

    const [serverInp, setServerInp] = useState(selectServer || '');
    const [userInp, setUserInp] = useState(selectUser || '');
    const [emailInp, setEmailInp] = useState(selectEmail || '');
    const [startDateInp, setStartDateInp] = useState(startDate || '');
    const [endDateInp, setEndDateInp] = useState(endDate || '');

    const [serverAr, setServerAr] = useState([]);
    const [userAr, setUserAr] = useState([]);
    const [totalAr, setTotalAr] = useState([]);
    const [totalSent, setTotalSent] = useState(0);
    const [totalReceive, setTotalReceive] = useState(0);
    const [bouncePercent, setBouncePercent] = useState(0);

    const handleInput = (e) => {
        if (e.target.name === 'email') {
            setEmailInp(e.target.value);
        }
        if (e.target.name === 'start_date') {
            setStartDateInp(e.target.value);
        }
        if (e.target.name === 'end_date') {
            setEndDateInp(e.target.value);
        }
    };
    const handleSearchClear = () => {
        setServerInp('');
        setUserInp('');
        setEmailInp('');
        setStartDateInp(new Date().toISOString().slice(0, 10));
        setEndDateInp(new Date().toISOString().slice(0, 10));
        navigate(`/email/report/`);
    };

    const handleSearch = () => {
        navigate(
            `/email/report/?select_server=${serverInp || ''}&select_user=${userInp || ''}&email=${
                emailInp || ''
            }&start_date=${startDateInp || ''}&end_date=${endDateInp || ''}`
        );
    };
    const handlePrint = () => {
        navigate(
            `/email/report/print/?select_server=${serverInp || ''}&select_user=${
                userInp || ''
            }&email=${emailInp || ''}&start_date=${startDateInp || ''}&end_date=${endDateInp || ''}`
        );
    };
    useEffect(() => {
        AxiosAuth.get(
            `${ConfigApi.API_REPORT_INFO}?select_server=${selectServer || ''}&select_user=${
                selectUser || ''
            }&email=${selectEmail || ''}&start_date=${startDate || ''}&end_date=${endDate || ''}`
        ).then((response) => {
            setServerAr(response.data.serverAr);
            setUserAr(response.data.userAr);
            setTotalAr(response.data.totalAr);
            setTotalSent(response.data.totalSent);
            setTotalReceive(response.data.totalReceive);
            setBouncePercent(response.data.bouncePercent);
            setStartDateInp(response.data.timeStart);
            setEndDateInp(response.data.timeEnd);
        });
    }, [endDate, selectEmail, selectServer, selectUser, startDate]);

    let bg = '#F3F3F5';
    let textCol: 'black';

    if (bouncePercent <= 2) {
        bg = '#0080002e';
        textCol = 'green';
    }
    if (bouncePercent > 2 && bouncePercent <= 5) {
        bg = '#ffff0047';
        textCol = '#b7b71c';
    } else {
        bg = '#ff000040';
        textCol = 'red';
    }
    return (
        <>
            <HeaderSection />
            <div className="main-panel">
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <input type="hidden" name="select_server" value={serverInp} />
                            <DropdownMenu
                                selectedOption={serverInp}
                                setSelectedOption={setServerInp}
                                options={serverAr ? Object.values(serverAr) : []}
                                width="100%"
                                defaultOption="Select Server"
                            />
                        </div>
                        <div className="sidebar-panel-search">
                            <input type="hidden" name="select_user" value={userInp} />
                            <DropdownMenu
                                selectedOption={userInp}
                                setSelectedOption={setUserInp}
                                options={userAr ? Object.values(userAr) : []}
                                width="100%"
                                defaultOption="Select User"
                            />
                        </div>
                        <div className="sidebar-panel-search">
                            <input
                                type="text"
                                name="email"
                                placeholder="Email"
                                value={emailInp}
                                onChange={handleInput}
                            />
                        </div>
                        <div className="sidebar-panel-search">
                            <input
                                type="date"
                                name="start_date"
                                value={startDateInp}
                                onChange={handleInput}
                            />
                        </div>
                        <div className="sidebar-panel-search">
                            <input
                                type="date"
                                name="end_date"
                                value={endDateInp}
                                onChange={handleInput}
                            />
                        </div>

                        <button type="button" onClick={handleSearch}>
                            Search
                        </button>
                        {selectEmail || selectServer || selectUser || startDate || endDate ? (
                            <button type="button" className="cross-btn" onClick={handleSearchClear}>
                                <img src={IconCross} alt="Cross icon" />
                            </button>
                        ) : null}
                    </form>
                    <button type="button" onClick={handlePrint}>
                        Print
                    </button>
                </div>
                <div className="main-panel-body">
                    {totalAr ? (
                        <div className="main-panel-report">
                            <table className="main-panel-body-table mb-3">
                                <thead className="main-panel-body-table-thead">
                                    <tr className="main-panel-head-table-tr">
                                        <th className="main-panel-body-table-th " colSpan="3">
                                            Incoming
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="main-panel-body-table-tr">
                                        <td className="main-panel-body-table-td">Total Received</td>
                                        <td className="main-panel-body-table-td">{totalReceive}</td>
                                        <td className="main-panel-body-table-td" />
                                    </tr>
                                    <tr className="main-panel-body-table-tr">
                                        <td className="main-panel-body-table-td">Total Success</td>
                                        <td className="main-panel-body-table-td">
                                            <Link
                                                to={`/email/report/details?type=success&type2=in&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                            >
                                                {totalAr?.Success?.Incoming}
                                            </Link>
                                        </td>
                                        <td className="main-panel-body-table-td">
                                            {totalAr?.Success?.InPercent} %
                                        </td>
                                    </tr>

                                    <tr className="main-panel-body-table-tr">
                                        <td className="main-panel-body-table-td">
                                            Total InProgress
                                        </td>
                                        <td className="main-panel-body-table-td">
                                            <Link
                                                to={`/email/report/details?type=inprogress&type2=in&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                            >
                                                {totalAr?.InProgress?.Incoming}
                                            </Link>
                                        </td>
                                        <td className="main-panel-body-table-td">
                                            {totalAr?.InProgress?.InPercent} %
                                        </td>
                                    </tr>

                                    <tr className="main-panel-body-table-tr">
                                        <td className="main-panel-body-table-td">Total Reject</td>
                                        <td className="main-panel-body-table-td">
                                            <Link
                                                to={`/email/report/details?type=rejected&type2=in&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                            >
                                                {totalAr?.Reject?.Incoming}
                                            </Link>
                                        </td>
                                        <td className="main-panel-body-table-td">
                                            {totalAr?.Reject?.InPercent} %
                                        </td>
                                    </tr>

                                    <tr className="main-panel-body-table-tr">
                                        <td className="main-panel-body-table-td">Total Failure</td>
                                        <td className="main-panel-body-table-td">
                                            <Link
                                                to={`/email/report/details?type=failure&type2=in&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                            >
                                                {totalAr?.Failure?.Incoming}
                                            </Link>
                                        </td>
                                        <td className="main-panel-body-table-td">
                                            {totalAr?.Failure?.InPercent} %
                                        </td>
                                    </tr>
                                    <tr className="main-panel-body-table-tr">
                                        <td className="main-panel-body-table-td">Total Defer</td>
                                        <td className="main-panel-body-table-td">
                                            <Link
                                                to={`/email/report/details/defer/?type2=in&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                            >
                                                {totalAr?.Defer?.Incoming}
                                            </Link>
                                        </td>
                                        <td className="main-panel-body-table-td">
                                            {totalAr?.Defer?.InPercent} %
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="main-panel-body-table mb-3">
                                <thead className="main-panel-body-table-thead">
                                    <tr className="main-panel-head-table-tr">
                                        <th className="main-panel-body-table-th " colSpan="3">
                                            Outgoing
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="main-panel-body-table-tr">
                                        <td className="main-panel-body-table-td">Total Sent</td>
                                        <td className="main-panel-body-table-td">{totalSent}</td>
                                        <td className="main-panel-body-table-td" />
                                    </tr>

                                    <tr className="main-panel-body-table-tr">
                                        <td className="main-panel-body-table-td">Total Success</td>
                                        <td className="main-panel-body-table-td">
                                            <Link
                                                to={`/email/report/details?type=success&type2=out&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                            >
                                                {totalAr?.Success?.Outgoing}
                                            </Link>
                                        </td>
                                        <td className="main-panel-body-table-td">
                                            {totalAr?.Success?.OutPercent} %
                                        </td>
                                    </tr>

                                    <tr className="main-panel-body-table-tr">
                                        <td className="main-panel-body-table-td">
                                            Total InProgress
                                        </td>
                                        <td className="main-panel-body-table-td">
                                            <Link
                                                to={`/email/report/details?type=inprogress&type2=out&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                            >
                                                {totalAr?.InProgress?.Outgoing}
                                            </Link>
                                        </td>
                                        <td className="main-panel-body-table-td">
                                            {totalAr?.InProgress?.OutPercent} %
                                        </td>
                                    </tr>

                                    <tr className="main-panel-body-table-tr">
                                        <td className="main-panel-body-table-td">Total Reject</td>
                                        <td className="main-panel-body-table-td">
                                            <Link
                                                to={`/email/report/details?type=rejected&type2=out&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                            >
                                                {totalAr?.Reject?.Outgoing}
                                            </Link>
                                        </td>
                                        <td className="main-panel-body-table-td">
                                            {totalAr?.Reject?.OutPercent} %
                                        </td>
                                    </tr>

                                    <tr className="main-panel-body-table-tr">
                                        <td className="main-panel-body-table-td">Total Failure</td>
                                        <td className="main-panel-body-table-td">
                                            <Link
                                                to={`/email/report/details?type=failure&type2=out&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                            >
                                                {totalAr?.Failure?.Outgoing}
                                            </Link>
                                        </td>
                                        <td className="main-panel-body-table-td">
                                            {totalAr?.Failure?.OutPercent} %
                                        </td>
                                    </tr>
                                    <tr className="main-panel-body-table-tr">
                                        <td className="main-panel-body-table-td">Total Defer</td>
                                        <td className="main-panel-body-table-td">
                                            <Link
                                                to={`/email/report/details/defer/?type2=out&select_server=${serverInp}&select_user=${userInp}&email=${emailInp}&start_date=${startDateInp}&end_date=${endDateInp}`}
                                            >
                                                {totalAr?.Defer?.Outgoing}
                                            </Link>
                                        </td>
                                        <td className="main-panel-body-table-td">
                                            {totalAr?.Defer?.OutPercent} %
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="main-panel-body-table mb-3" style={{ width: '30%' }}>
                                <thead className="main-panel-body-table-thead">
                                    <tr
                                        className="main-panel-head-table-tr"
                                        style={{ background: bg, color: textCol }}
                                    >
                                        <th
                                            className="main-panel-body-table-th"
                                            style={{ color: textCol }}
                                        >
                                            Bounce Rate
                                        </th>
                                        <th
                                            className="main-panel-body-table-th"
                                            style={{ color: textCol }}
                                        >
                                            {bouncePercent} %
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    ) : (
                        'No Data Found'
                    )}
                </div>
            </div>
        </>
    );
}

export default ReportInfo;
